import { TransformationType } from "@cxp-shared/contracts";
import { ConditionalPlaceholderUtils, DataSources, PlaceholderGenerator, PlaceholderUtils } from "@cxp-shared/personalization-core";
var convertOutputReferenceToSourceAndSetDefaultValue = function (placeholder, source, sourceType, defaultValue) {
    var _a;
    if (!((_a = placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding) === null || _a === void 0 ? void 0 : _a.source)) {
        return placeholder;
    }
    var placeholderCopy = JSON.parse(JSON.stringify(placeholder));
    var rootBinding = PlaceholderUtils.getRootBinding(placeholderCopy === null || placeholderCopy === void 0 ? void 0 : placeholderCopy.binding);
    rootBinding.source = source;
    rootBinding.inputs = {
        sourceType: {
            value: sourceType
        }
    };
    placeholderCopy.defaultValue = defaultValue;
    return placeholderCopy;
};
var convertOutputSourceToReference = function (placeholder, reference) {
    if (!placeholder) {
        return placeholder;
    }
    var placeholderCopy = JSON.parse(JSON.stringify(placeholder));
    var rootBinding = PlaceholderUtils.getRootBinding(placeholderCopy === null || placeholderCopy === void 0 ? void 0 : placeholderCopy.binding);
    rootBinding.source = reference;
    delete rootBinding.inputs;
    return placeholderCopy;
};
export var convertItemsToBinding = function (placeholder) {
    if (!isConvertableListPlaceholder(placeholder)) {
        return placeholder;
    }
    var convertedPlaceholder = JSON.parse(JSON.stringify(placeholder));
    var outputs = JSON.parse(JSON.stringify(placeholder.items[0].properties));
    Object.values(outputs).forEach(function (property) {
        replaceBindingSource(property, DataSources.ContextParentSource, DataSources.ContextOutputBase);
        property.category = "general";
    });
    convertedPlaceholder.binding.outputs = outputs;
    Object.values(convertedPlaceholder.items[0].properties).forEach(function (property) {
        delete property.binding;
    });
    return convertedPlaceholder;
};
var isConvertableListPlaceholder = function (placeholder) {
    var _a, _b, _c;
    return (placeholder &&
        placeholder.category === "list" &&
        ((_a = placeholder.binding) === null || _a === void 0 ? void 0 : _a.outputType) === "entityCollection" &&
        (!((_b = placeholder.binding) === null || _b === void 0 ? void 0 : _b.outputs) || Object.keys(placeholder.binding.outputs).length === 0) &&
        ((_c = placeholder.items) === null || _c === void 0 ? void 0 : _c.length) === 1 &&
        placeholder.items[0].properties &&
        Object.keys(placeholder.items[0].properties).length);
};
var replaceBindingSource = function (property, oldSource, newSource) {
    var _a;
    if (((_a = property.binding) === null || _a === void 0 ? void 0 : _a.source) === oldSource) {
        property.binding.source = newSource;
    }
};
var isAdvancedListPlaceholder = function (placeholder) {
    var _a, _b, _c, _d;
    var outputs = (_a = placeholder.binding) === null || _a === void 0 ? void 0 : _a.outputs;
    var properties = (_c = (_b = placeholder.items) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.properties;
    return (placeholder &&
        placeholder.category === "list" &&
        ((_d = placeholder.binding) === null || _d === void 0 ? void 0 : _d.outputType) === "entityCollection" &&
        outputs &&
        Object.keys(outputs).length > 0 &&
        (Object.values(outputs).some(function (p) {
            var _a;
            var rootBinding = PlaceholderUtils.getRootBinding(p === null || p === void 0 ? void 0 : p.binding);
            var hasTraversal = (rootBinding === null || rootBinding === void 0 ? void 0 : rootBinding.source) === DataSources.ContextOutputBaseRelated;
            var hasDateFormat = (p === null || p === void 0 ? void 0 : p.format) === "date" || (p === null || p === void 0 ? void 0 : p.format) === "date-time" || (p === null || p === void 0 ? void 0 : p.format) === "time";
            var hasCondition = ((_a = p === null || p === void 0 ? void 0 : p.transformation) === null || _a === void 0 ? void 0 : _a.type) === TransformationType.ComplexExpression;
            var hasRawOutputFormat = (rootBinding === null || rootBinding === void 0 ? void 0 : rootBinding.outputFormat) === "raw";
            return hasTraversal || hasDateFormat || hasCondition || hasRawOutputFormat;
        }) ||
            (properties &&
                Object.keys(properties).length > 0 &&
                Object.values(properties).some(function (p) {
                    var hasDefaultValue = !!(p === null || p === void 0 ? void 0 : p.defaultValue);
                    return hasDefaultValue;
                }))));
};
var generatePlaceholderWithNameAndTransformation = function (name, transformation, source, sourceType) {
    var newPlaceholder = PlaceholderGenerator.generateEmptyPlaceholder({
        placeholderCategory: "condition",
        title: name,
        type: ["null", "boolean"]
    });
    if (transformation) {
        var expression = ConditionalPlaceholderUtils.convertExpressionReferenceToSource(transformation === null || transformation === void 0 ? void 0 : transformation.expression, source, sourceType);
        newPlaceholder.transformation = {
            type: TransformationType.ComplexExpression,
            expression: expression
        };
    }
    return newPlaceholder;
};
export var ListPlaceholderUtils = {
    convertItemsToBinding: convertItemsToBinding,
    convertOutputReferenceToSourceAndSetDefaultValue: convertOutputReferenceToSourceAndSetDefaultValue,
    convertOutputSourceToReference: convertOutputSourceToReference,
    isAdvancedListPlaceholder: isAdvancedListPlaceholder,
    generatePlaceholderWithNameAndTransformation: generatePlaceholderWithNameAndTransformation
};
