var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { SourceTypes } from "@cxp-shared/metadata";
import { brandLogicalName } from "./MetadataConstants";
var MetadataStoreServiceMock = /** @class */ (function () {
    function MetadataStoreServiceMock(additionalEvents) {
        if (additionalEvents === void 0) { additionalEvents = []; }
        this.additionalEvents = additionalEvents;
        this.legalError = false;
        this.eventError = false;
        this.dataverseErrror = false;
        this.failingSourceName = "";
        this.ExternalPartyMetadata = [
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "5d215dac-2fee-e411-80d9-00155dcf6500",
                logicalName: "fullname",
                displayName: "Full Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.fullname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "6182700a-7497-412b-9261-48cbb1dc499b",
                logicalName: "createdonbehalfbyyominame",
                displayName: "createdonbehalfbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.createdonbehalfbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "68f9028a-ec88-496c-b804-75c9b89e8b6c",
                logicalName: "modifiedonbehalfby",
                displayName: "Modified By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c4eb581b-48b0-45f1-a27c-6a95ac0a790f",
                logicalName: "owningteam",
                displayName: "Owning Team",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    team: {
                        displayName: "Team",
                        primaryId: "teamid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "teamid"
                    }
                },
                navigationPaths: ["externalparty.owningteam"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "a784829f-d576-4ffb-9a18-39cd080b890e",
                logicalName: "statecode",
                displayName: "Status",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "state",
                referencedSources: null,
                navigationPaths: ["externalparty.statecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "5fe5457a-ae92-4457-b9fe-b809480cb317",
                logicalName: "owneridname",
                displayName: "owneridname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.owneridname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d23ec957-a901-43af-b0cc-909ab20448ad",
                logicalName: "statuscode",
                displayName: "Status Reason",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "status",
                referencedSources: null,
                navigationPaths: ["externalparty.statuscode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e0e7558-3603-460d-813f-01875f548ab9",
                logicalName: "lastenabledon",
                displayName: "Last Enabled On",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["externalparty.lastenabledon"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8efe2e1f-421a-4e46-b264-7b1e148e5eab",
                logicalName: "createdonbehalfby",
                displayName: "Created By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "74181c4e-af93-4251-9ed7-873104204380",
                logicalName: "transactioncurrencyid",
                displayName: "Currency",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    transactioncurrency: {
                        displayName: "Transactional Currency",
                        primaryId: "transactioncurrencyid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "transactioncurrencyid"
                    }
                },
                navigationPaths: ["externalparty.transactioncurrencyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "b9e48cee-fa71-4ad6-b6bb-bfa395d2d0f4",
                logicalName: "yomifirstname",
                displayName: "Yomi First Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifirstname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "cd00e578-1414-4ca5-9447-ec6b1f5daf1a",
                logicalName: "owninguser",
                displayName: "Owning User",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.owninguser"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d380e7d3-a379-4ef5-bb42-9189a96bcf1f",
                logicalName: "yomifullname",
                displayName: "Yomi Full Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifullname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c08b20eb-ba56-419b-8c9b-96f62fb2490e",
                logicalName: "externalpartyid",
                displayName: "External Party",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "76605270-015a-4919-b350-08ff6ba5fd40",
                logicalName: "importsequencenumber",
                displayName: "Import Sequence Number",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.importsequencenumber"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "04eb41db-34cd-4751-90f6-421b3c1e9d4c",
                logicalName: "middlename",
                displayName: "Middle Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.middlename"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "6d5b3f75-5398-4ed4-b2ef-46a52e2eca0e",
                logicalName: "emailaddress",
                displayName: "Email Address",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.emailaddress"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8c1b33b6-0c3f-44a7-a27d-1cf873f9a0f3",
                logicalName: "utcconversiontimezonecode",
                displayName: "UTC Conversion Time Zone Code",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.utcconversiontimezonecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "044ecd2a-51a1-4106-8557-9bed7ee7b5bc",
                logicalName: "createdbyyominame",
                displayName: "createdbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.createdbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "9345c60c-7190-4de4-b3a5-f6789d6e0535",
                logicalName: "owningbusinessunit",
                displayName: "Owning Business Unit",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    businessunit: {
                        primaryId: "businessunitId",
                        displayName: "Business Unit",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "businessunitId"
                    }
                },
                navigationPaths: ["externalparty.owningbusinessunit"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "1ea0ed03-fbd6-4dd8-84d3-82075e72e519",
                logicalName: "modifiedbyname",
                displayName: "modifiedbyname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7fbd020a-3230-482e-8012-035adfd03ac7",
                logicalName: "externalpartyidunique",
                displayName: "External Party Unique Id",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyidunique"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "dfea25e6-1f28-4dfa-a649-cac7feacb56f",
                logicalName: "modifiedby",
                displayName: "Modified By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e89cf0c-eaf8-4a3a-ae4f-12456d2f3675",
                logicalName: "modifiedbyyominame",
                displayName: "modifiedbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c399e75d-3ecf-4257-a28d-4c92bc58b797",
                logicalName: "createdby",
                displayName: "Created By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "68f9028a-ec88-496c-b804-75c9b89e8b6c",
                logicalName: "modifiedonbehalfby",
                displayName: "Modified By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c4eb581b-48b0-45f1-a27c-6a95ac0a790f",
                logicalName: "owningteam",
                displayName: "Owning Team",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    team: {
                        displayName: "Team",
                        primaryId: "teamid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "teamid"
                    }
                },
                navigationPaths: ["externalparty.owningteam"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "a784829f-d576-4ffb-9a18-39cd080b890e",
                logicalName: "statecode",
                displayName: "Status",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "state",
                referencedSources: null,
                navigationPaths: ["externalparty.statecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "5fe5457a-ae92-4457-b9fe-b809480cb317",
                logicalName: "owneridname",
                displayName: "owneridname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.owneridname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d23ec957-a901-43af-b0cc-909ab20448ad",
                logicalName: "statuscode",
                displayName: "Status Reason",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "status",
                referencedSources: null,
                navigationPaths: ["externalparty.statuscode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e0e7558-3603-460d-813f-01875f548ab9",
                logicalName: "lastenabledon",
                displayName: "Last Enabled On",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["externalparty.lastenabledon"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8efe2e1f-421a-4e46-b264-7b1e148e5eab",
                logicalName: "createdonbehalfby",
                displayName: "Created By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "74181c4e-af93-4251-9ed7-873104204380",
                logicalName: "transactioncurrencyid",
                displayName: "Currency",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    transactioncurrency: {
                        displayName: "Transactional Currency",
                        primaryId: "transactioncurrencyid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "transactioncurrencyid"
                    }
                },
                navigationPaths: ["externalparty.transactioncurrencyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "b9e48cee-fa71-4ad6-b6bb-bfa395d2d0f4",
                logicalName: "yomifirstname",
                displayName: "Yomi First Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifirstname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "cd00e578-1414-4ca5-9447-ec6b1f5daf1a",
                logicalName: "owninguser",
                displayName: "Owning User",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.owninguser"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d380e7d3-a379-4ef5-bb42-9189a96bcf1f",
                logicalName: "yomifullname",
                displayName: "Yomi Full Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifullname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c08b20eb-ba56-419b-8c9b-96f62fb2490e",
                logicalName: "externalpartyid",
                displayName: "External Party",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "76605270-015a-4919-b350-08ff6ba5fd40",
                logicalName: "importsequencenumber",
                displayName: "Import Sequence Number",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.importsequencenumber"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "04eb41db-34cd-4751-90f6-421b3c1e9d4c",
                logicalName: "middlename",
                displayName: "Middle Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.middlename"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "6d5b3f75-5398-4ed4-b2ef-46a52e2eca0e",
                logicalName: "emailaddress",
                displayName: "Email Address",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.emailaddress"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8c1b33b6-0c3f-44a7-a27d-1cf873f9a0f3",
                logicalName: "utcconversiontimezonecode",
                displayName: "UTC Conversion Time Zone Code",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.utcconversiontimezonecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "044ecd2a-51a1-4106-8557-9bed7ee7b5bc",
                logicalName: "createdbyyominame",
                displayName: "createdbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.createdbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "9345c60c-7190-4de4-b3a5-f6789d6e0535",
                logicalName: "owningbusinessunit",
                displayName: "Owning Business Unit",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    businessunit: {
                        primaryId: "businessunitId",
                        displayName: "Business Unit",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "businessunitId"
                    }
                },
                navigationPaths: ["externalparty.owningbusinessunit"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "1ea0ed03-fbd6-4dd8-84d3-82075e72e519",
                logicalName: "modifiedbyname",
                displayName: "modifiedbyname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7fbd020a-3230-482e-8012-035adfd03ac7",
                logicalName: "externalpartyidunique",
                displayName: "External Party Unique Id",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyidunique"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "dfea25e6-1f28-4dfa-a649-cac7feacb56f",
                logicalName: "modifiedby",
                displayName: "Modified By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e89cf0c-eaf8-4a3a-ae4f-12456d2f3675",
                logicalName: "modifiedbyyominame",
                displayName: "modifiedbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c399e75d-3ecf-4257-a28d-4c92bc58b797",
                logicalName: "createdby",
                displayName: "Created By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "68f9028a-ec88-496c-b804-75c9b89e8b6c",
                logicalName: "modifiedonbehalfby",
                displayName: "Modified By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c4eb581b-48b0-45f1-a27c-6a95ac0a790f",
                logicalName: "owningteam",
                displayName: "Owning Team",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    team: {
                        displayName: "Team",
                        primaryId: "teamid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "teamid"
                    }
                },
                navigationPaths: ["externalparty.owningteam"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "a784829f-d576-4ffb-9a18-39cd080b890e",
                logicalName: "statecode",
                displayName: "Status",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "state",
                referencedSources: null,
                navigationPaths: ["externalparty.statecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "5fe5457a-ae92-4457-b9fe-b809480cb317",
                logicalName: "owneridname",
                displayName: "owneridname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.owneridname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d23ec957-a901-43af-b0cc-909ab20448ad",
                logicalName: "statuscode",
                displayName: "Status Reason",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "status",
                referencedSources: null,
                navigationPaths: ["externalparty.statuscode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e0e7558-3603-460d-813f-01875f548ab9",
                logicalName: "lastenabledon",
                displayName: "Last Enabled On",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["externalparty.lastenabledon"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8efe2e1f-421a-4e46-b264-7b1e148e5eab",
                logicalName: "createdonbehalfby",
                displayName: "Created By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "74181c4e-af93-4251-9ed7-873104204380",
                logicalName: "transactioncurrencyid",
                displayName: "Currency",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    transactioncurrency: {
                        displayName: "Transactional Currency",
                        primaryId: "transactioncurrencyid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "transactioncurrencyid"
                    }
                },
                navigationPaths: ["externalparty.transactioncurrencyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "b9e48cee-fa71-4ad6-b6bb-bfa395d2d0f4",
                logicalName: "yomifirstname",
                displayName: "Yomi First Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifirstname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "cd00e578-1414-4ca5-9447-ec6b1f5daf1a",
                logicalName: "owninguser",
                displayName: "Owning User",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.owninguser"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "d380e7d3-a379-4ef5-bb42-9189a96bcf1f",
                logicalName: "yomifullname",
                displayName: "Yomi Full Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.yomifullname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c08b20eb-ba56-419b-8c9b-96f62fb2490e",
                logicalName: "externalpartyid",
                displayName: "External Party",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "76605270-015a-4919-b350-08ff6ba5fd40",
                logicalName: "importsequencenumber",
                displayName: "Import Sequence Number",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.importsequencenumber"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "04eb41db-34cd-4751-90f6-421b3c1e9d4c",
                logicalName: "middlename",
                displayName: "Middle Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.middlename"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "6d5b3f75-5398-4ed4-b2ef-46a52e2eca0e",
                logicalName: "emailaddress",
                displayName: "Email Address",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.emailaddress"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "8c1b33b6-0c3f-44a7-a27d-1cf873f9a0f3",
                logicalName: "utcconversiontimezonecode",
                displayName: "UTC Conversion Time Zone Code",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.utcconversiontimezonecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "044ecd2a-51a1-4106-8557-9bed7ee7b5bc",
                logicalName: "createdbyyominame",
                displayName: "createdbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.createdbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "9345c60c-7190-4de4-b3a5-f6789d6e0535",
                logicalName: "owningbusinessunit",
                displayName: "Owning Business Unit",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    businessunit: {
                        primaryId: "businessunitId",
                        displayName: "Business Unit",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "businessunitId"
                    }
                },
                navigationPaths: ["externalparty.owningbusinessunit"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "1ea0ed03-fbd6-4dd8-84d3-82075e72e519",
                logicalName: "modifiedbyname",
                displayName: "modifiedbyname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7fbd020a-3230-482e-8012-035adfd03ac7",
                logicalName: "externalpartyidunique",
                displayName: "External Party Unique Id",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["externalparty.externalpartyidunique"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "dfea25e6-1f28-4dfa-a649-cac7feacb56f",
                logicalName: "modifiedby",
                displayName: "Modified By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.modifiedby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7e89cf0c-eaf8-4a3a-ae4f-12456d2f3675",
                logicalName: "modifiedbyyominame",
                displayName: "modifiedbyyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["externalparty.modifiedbyyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c399e75d-3ecf-4257-a28d-4c92bc58b797",
                logicalName: "createdby",
                displayName: "Created By",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["externalparty.createdby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "c8960d1d-e01a-40c0-9eaa-0335528e5798",
                logicalName: "timezoneruleversionnumber",
                displayName: "Time Zone Rule Version Number",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["externalparty.timezoneruleversionnumber"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "externalparty",
                sourcePrimaryId: "externalpartyid",
                sourceSetName: "externalparties",
                sourceDisplayName: "External Party",
                nativeId: "7ad9ed7b-ef72-4996-98d2-86251222e021",
                logicalName: "owneridtype",
                displayName: "owneridtype",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "entityValue",
                referencedSources: null,
                navigationPaths: ["externalparty.owneridtype"],
                fullMetadata: null,
                businessUnit: null
            }
        ];
        this.contact = [
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "0523d901-ab88-eb11-b1ad-000d3a9e9c58",
                logicalName: "msevtmgt_contact_msevtmgt_room_PrimaryContact",
                displayName: "Rooms",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "oneToManyRelationship",
                referencedSources: {
                    msevtmgt_room: {
                        displayName: "Room",
                        primaryId: "msevtmgt_roomid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "msevtmgt_roomid"
                    }
                },
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "0523d901-ab88-eb11-b1ad-000d3a9e9c58",
                logicalName: "msevtmgt_contact_externalparty_Parties",
                displayName: "Parties",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "oneToManyRelationship",
                referencedSources: {
                    externalparty: {
                        displayName: "External Party",
                        primaryId: "externalpartyid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "externalpartyid"
                    }
                },
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "29f6cc35-e1bc-47b0-bea7-29fece1842f3",
                logicalName: "createdbyexternalparty",
                displayName: "Created By (External Party)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    externalparty: {
                        primaryId: "externalpartyId",
                        displayName: "External Party",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "externalpartyId"
                    }
                },
                navigationPaths: ["contact.createdbyexternalparty"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "a6e910a3-7504-49f7-8a03-6c80e733886e",
                logicalName: "creditonhold",
                displayName: "Credit Hold",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: ["contact.creditonhold"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "d191d24a-2fbd-48b7-9101-e8a7d2f1bf27",
                logicalName: "entityimage_url",
                displayName: "entityimage_url",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.entityimage_url"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "dbcf6b92-8985-426b-8b39-377cf5c16fed",
                logicalName: "contact_email",
                displayName: "Contact email",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.contact_email"],
                fullMetadata: null,
                businessUnit: null,
                stringFormatType: "email"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "10ab4019-4ad5-4451-878b-b8cbc610ebf9",
                logicalName: "contact_phone",
                displayName: "Contact phone",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.contact_phone"],
                fullMetadata: null,
                businessUnit: null,
                stringFormatType: "phone"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "f9c7b4ce-19d1-4d51-a47d-1a567cc773b9",
                logicalName: "contact_json",
                displayName: "Contact json",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.contact_json"],
                fullMetadata: null,
                businessUnit: null,
                stringFormatType: "json"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "ab504794-dedf-4115-8736-9d4c3b298947",
                logicalName: "preferredsystemuseridname",
                displayName: "preferredsystemuseridname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.preferredsystemuseridname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "971c238a-987d-4b8b-81ee-50418caff142",
                logicalName: "owningbusinessunit",
                displayName: "Owning Business Unit",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    businessunit: {
                        displayName: "Business unit",
                        primaryId: "businessunitId",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "businessunitId"
                    }
                },
                navigationPaths: ["contact.owningbusinessunit"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "89802cd9-fe13-4612-8cac-e3593d3da38c",
                logicalName: "new_samhtestmultiselect",
                displayName: "Samh test multiselect",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "multiSelectPicklist",
                referencedSources: null,
                navigationPaths: ["contact.new_samhtestmultiselect"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "89802cd9-fe13-4612-8cac-e3593d3da38d",
                logicalName: "msgdpr_gdprconsent",
                displayName: "Consent given",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "picklist",
                referencedSources: null,
                navigationPaths: ["contact.msgdpr_gdprconsent"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "9fb79f93-3449-4dec-a6b6-96faf3fdc0c7",
                logicalName: "birthdate",
                displayName: "Birthday",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["contact.birthdate"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "9fb79f93-3449-4dec-a6b6-96faf3fdc0c7",
                logicalName: "dateonlyfield",
                displayName: "DateOnlyField",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["contact.dateonlyfield"],
                fullMetadata: null,
                businessUnit: null,
                dateTimeBehavior: "dateOnly"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "9fb79f93-3449-4dec-a6b6-96faf3fdc0c7",
                logicalName: "dateuserlocalfield",
                displayName: "DateUserLocalField",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["contact.dateuserlocalfield"],
                fullMetadata: null,
                businessUnit: null,
                dateTimeBehavior: "userLocal"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "9fb79f93-3449-4dec-a6b6-96faf3fdc0c7",
                logicalName: "datetimezoneindependentfield",
                displayName: "DateTimeZoneIndependentField",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["contact.datetimezoneindependentfield"],
                fullMetadata: null,
                businessUnit: null,
                dateTimeBehavior: "timeZoneIndependent"
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "89802cd9-fe13-4612-8cac-e3593d3da38b",
                logicalName: "preferredappointmenttimecode",
                displayName: "Preferred Time",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "picklist",
                referencedSources: null,
                navigationPaths: ["contact.preferredappointmenttimecode"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "8ee45967-ddcd-4aca-8267-5a09e7b7fca3",
                logicalName: "preferredappointmentdaycodename",
                displayName: "preferredappointmentdaycodename",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "entityValue",
                referencedSources: null,
                navigationPaths: ["contact.preferredappointmentdaycodename"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "4e5b7584-2338-456f-ad66-af833270bcf8",
                logicalName: "address2_stateorprovince",
                displayName: "Address 2: State/Province",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.address2_stateorprovince"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "ad58c070-6fb1-49b9-ba99-d3063c92b4c2",
                logicalName: "participatesinworkflowname",
                displayName: "participatesinworkflowname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "entityValue",
                referencedSources: null,
                navigationPaths: ["contact.participatesinworkflowname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "3c53ad8a-04ac-4a14-a73f-24de4c721e28",
                logicalName: "mobilephone",
                displayName: "Mobile Phone",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.mobilephone"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "50e3b9b2-7b95-45a3-862e-b705323d5658",
                logicalName: "firstname",
                displayName: "First Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.firstname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "50e3b9b2-7b95-45a3-862e-b705323d5658",
                logicalName: "securefield",
                displayName: "Secure Field",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: true,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.securefield"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "a1faadf0-b996-45e5-b94d-443f3d8f3258",
                logicalName: "lastname",
                displayName: "Last Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.lastname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "a3ff93d1-112e-4c21-b671-6fc079886df8",
                logicalName: "parentcustomeridyominame",
                displayName: "parentcustomeridyominame",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.parentcustomeridyominame"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "0771872b-d4f8-4d8b-a6d5-51d702286deb",
                logicalName: "address2_country",
                displayName: "Address 2: Country/Region",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.address2_country"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "312bebd3-dceb-4c31-9196-7f5c15896d15",
                logicalName: "teamsfollowed",
                displayName: "TeamsFollowed",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: ["contact.teamsfollowed"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "ddddbf82-ab85-494d-b0f2-007f18a9bb89",
                logicalName: "address2_line2",
                displayName: "Address 2: Street 2",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.address2_line2"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "12a53d16-d41b-42a0-b345-00d93a8f5e08",
                logicalName: "address3_stateorprovince",
                displayName: "Address3: State/Province",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.address3_stateorprovince"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "926917ac-da30-41d7-b6a5-93c8baccd8d2",
                logicalName: "modifiedonbehalfby",
                displayName: "Modified By (Delegate)",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "System User",
                        primaryId: "systemuserid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "systemuserid"
                    }
                },
                navigationPaths: ["contact.modifiedonbehalfby"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "589d672c-d005-4623-98dc-932ddf7f8915",
                logicalName: "slaid",
                displayName: "SLA",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    sla: {
                        displayName: "SLA",
                        primaryId: "slaid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "slaid"
                    }
                },
                navigationPaths: ["contact.slaid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "0c4f4c19-2abe-4685-9cd9-d4b31bd8fd45",
                logicalName: "preferredserviceid",
                displayName: "Preferred Service",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    service: {
                        displayName: "Service",
                        primaryId: "serviceid",
                        referencedAttribute: "externalpartyid",
                        referencingAttribute: "serviceid"
                    }
                },
                navigationPaths: ["contact.preferredserviceid"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "3b00aefa-0540-47e5-8d08-6e40451699fc",
                logicalName: "assistantphone",
                displayName: "Assistant Phone",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.assistantphone"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "c9e5caf8-6bf7-43a9-b8c3-5ec2ceb04d1e",
                logicalName: "address3_shippingmethodcodename",
                displayName: "address3_shippingmethodcodename",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "entityValue",
                referencedSources: null,
                navigationPaths: ["contact.address3_shippingmethodcodename"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "8f8bf64b-2425-478f-91dd-651a8ff21356",
                logicalName: "parentcontactid",
                displayName: "Parent Contact",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "contactid",
                        referencingAttribute: "parentcontactid",
                        referencedAttribute: "contactid",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["contact.parentcontactid"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "a7e43941-36f3-481d-991b-2e93fdfbe413",
                logicalName: "address1_name",
                displayName: "Address 1: Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["contact.address1_name"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "contact",
                sourcePrimaryId: "contactid",
                sourceSetName: "contacts",
                sourceDisplayName: "Contact",
                nativeId: "a247349a-78ab-4729-8528-515fc2719b67",
                logicalName: "statuscode",
                displayName: "Status Reason",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "status",
                referencedSources: null,
                navigationPaths: ["contact.statuscode"],
                fullMetadata: null,
                businessUnit: null
            }
        ];
        this.lead = [
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadId",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "50e3b9b2-7b95-45a3-862e-b705323d5658",
                logicalName: "firstname",
                displayName: "First Name",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["lead.firstname"],
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "0f8ca353-5b1d-4f0c-8d44-3f11d1f5f5a7",
                logicalName: "msdyncrm_degree",
                displayName: "Degree",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e1c4bb86-37d2-4b9a-97f6-83784449c8eb",
                logicalName: "processid",
                displayName: "Process Id",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "1b31177d-43a3-eb11-9442-000d3a575e61",
                logicalName: "new_noofdays",
                displayName: "No of days",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "bceaac1f-4423-4075-8f23-26c360968b20",
                logicalName: "msdyncrm_teleprospectready",
                displayName: "Teleprospecting ready",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "ffc69d64-adcf-4bfa-ad9f-f0806478d5a3",
                logicalName: "preferredcontactmethodcode",
                displayName: "Preferred Method of Contact",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "picklist",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e2857835-9237-4323-9be5-f7ad2e798a03",
                logicalName: "followemail",
                displayName: "Follow Email Activity",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e2857835-9237-4323-9be5-f7ad2e798a03",
                logicalName: "followemail2",
                displayName: "Follow Email Activity2",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e2857835-9237-4323-9be5-f7ad2e798a03",
                logicalName: "followemail3",
                displayName: "Follow Email Activity3",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e2857835-9237-4323-9be5-f7ad2e798a03",
                logicalName: "followemail4",
                displayName: "Follow Email Activity4",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "e2857835-9237-4323-9be5-f7ad2e798a03",
                logicalName: "followemail5",
                displayName: "Follow Email Activity5",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "2f6e27ef-8760-49af-b32b-acb58ee6e383",
                logicalName: "donotfax",
                displayName: "Do not allow Faxes",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "boolean",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "21356c0a-6f63-45e4-93be-fe26d9a37df6",
                logicalName: "msdyncrm_salesreadyname",
                displayName: "msdyncrm_salesreadyname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "other",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "37d06720-06a9-4bd9-8c12-d2f8f700ba89",
                logicalName: "address1_longitude",
                displayName: "Address 1: Longitude",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "double",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "82b4534b-db4c-4ab7-9a8d-1064068005a5",
                logicalName: "donotpostalmailname",
                displayName: "donotpostalmailname",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "other",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "lead",
                sourcePrimaryId: "leadid",
                sourceSetName: "leads",
                sourceDisplayName: "Lead",
                nativeId: "174d2141-a902-4204-9cee-38bc21cec515",
                logicalName: "address2_country",
                displayName: "Address 2: Country/Region",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            }
        ];
        this.msevtmgt_buildings = [
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "8f2ee582-b372-4bbd-bbc4-772d8b6da307",
                logicalName: "msevtmgt_buildingid",
                displayName: "Building",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: true,
                dataType: "uniqueidentifier",
                referencedSources: null,
                navigationPaths: ["msevtmgt_building.msevtmgt_buildingid"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "3b6c07a8-9151-4557-8a03-4236363fd39a",
                logicalName: "msevtmgt_city",
                displayName: "City",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: ["msevtmgt_building.msevtmgt_city"],
                fullMetadata: null,
                targetAudience: null,
                stringFormatType: "text",
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "4fabff9d-29c0-4716-8d2f-e8fa676553ae",
                logicalName: "createdon",
                displayName: "Created on",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "dateTime",
                referencedSources: null,
                navigationPaths: ["msevtmgt_building.createdon"],
                fullMetadata: null,
                targetAudience: null,
                dateTimeBehavior: "userLocal",
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "dbb0a2d0-0aa8-4fa7-9227-74808f9c5ffb",
                logicalName: "createdby",
                displayName: "Created by",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "lookup",
                referencedSources: {
                    systemuser: {
                        displayName: "User",
                        primaryId: "systemuserid",
                        referencingAttribute: "createdby",
                        referencedAttribute: "systemuserid",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["msevtmgt_building.createdby"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "ab4eb73c-9a7b-4e4f-bd56-3f2d3dbb5b5b",
                logicalName: "transactioncurrencyid",
                displayName: "Currency",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "lookup",
                referencedSources: {
                    transactioncurrency: {
                        displayName: "Currency",
                        primaryId: "transactioncurrencyid",
                        referencingAttribute: "transactioncurrencyid",
                        referencedAttribute: "transactioncurrencyid",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["msevtmgt_building.transactioncurrencyid"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "7ffc69a7-0f59-ed11-9562-000d3a9c9774",
                logicalName: "msevtmgt_building_msevtmgt_room_Building",
                displayName: "Rooms",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "oneToManyRelationship",
                referencedSources: {
                    msevtmgt_room: {
                        displayName: "Room",
                        primaryId: "msevtmgt_roomid",
                        referencingAttribute: "msevtmgt_buildingid",
                        referencedAttribute: "msevtmgt_building",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["msevtmgt_building.msevtmgt_building_msevtmgt_room_Building"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_building",
                sourcePrimaryId: "msevtmgt_buildingid",
                sourceSetName: "msevtmgt_buildings",
                sourceDisplayName: "Building",
                nativeId: "7ffc69a7-0f59-ed11-9562-000d3a9c9774",
                logicalName: "msevtmgt_building_msevtmgt_floor_Building",
                displayName: "Floors",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: false,
                dataType: "oneToManyRelationship",
                referencedSources: {
                    msevtmgt_room: {
                        displayName: "Floor",
                        primaryId: "msevtmgt_floorid",
                        referencingAttribute: "msevtmgt_buildingid",
                        referencedAttribute: "msevtmgt_building",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["msevtmgt_building.msevtmgt_building_msevtmgt_floor_Building"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            }
        ];
        this.msevtmgt_rooms = [
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "bacedb3c-eb36-43a8-aaa5-46af9e02afd2",
                logicalName: "msevtmgt_capacity",
                displayName: "Capacity",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "c681085f-a846-462b-9942-7cbd9b97f27c",
                logicalName: "msevtmgt_building",
                displayName: "Building",
                languageCode: 1033,
                iconPath: "msevtmgt_/Images/Venues",
                isSecured: false,
                isRequired: true,
                dataType: "lookup",
                referencedSources: {
                    msevtmgt_building: {
                        displayName: "Building",
                        primaryId: "msevtmgt_buildingid",
                        referencingAttribute: "msevtmgt_building",
                        referencedAttribute: "msevtmgt_buildingid",
                        isTargetAudience: false,
                        isVirtual: false
                    }
                },
                navigationPaths: ["msevtmgt_room.msevtmgt_building"],
                fullMetadata: null,
                targetAudience: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "bacedb3c-eb36-43a8-aaa5-46af9e02afd2",
                logicalName: "msevtmgt_capacity2",
                displayName: "Capacity",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "integer",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "85420c10-f681-442c-9500-270a0825d7dd",
                logicalName: "msevtmgt_name",
                displayName: "Name2",
                languageCode: 1033,
                iconPath: null,
                isRequired: true,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "85420c10-f681-442c-9500-270a0825d7dd",
                logicalName: "createdon",
                displayName: "Created On",
                languageCode: 1033,
                iconPath: null,
                isRequired: true,
                isSecured: false,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.DataverseTable,
                sourceLogicalName: "msevtmgt_room",
                sourcePrimaryId: "msevtmgt_roomid",
                sourceSetName: "msevtmgt_rooms",
                sourceDisplayName: "Room",
                nativeId: "85420c10-f681-442c-9500-270a0825d7dd",
                logicalName: "namesecured",
                displayName: "NameSecured",
                languageCode: 1033,
                iconPath: null,
                isRequired: true,
                isSecured: true,
                dataType: "string",
                referencedSources: null,
                navigationPaths: null,
                fullMetadata: null,
                businessUnit: null
            }
        ];
        this.events = [
            {
                sourceType: SourceTypes.CjoCustomEvent,
                dataType: "string",
                displayName: "Location",
                logicalName: "msdynmkt_location",
                sourceLogicalName: "msdynmkt_party",
                languageCode: 1033,
                nativeId: "ccc6cd06-a045-4279-8ea9-a96e12c011cc",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: ["msdynmkt_party.msdynmkt_location"],
                referencedSources: {
                    lead: {
                        displayName: "Lead",
                        primaryId: "aaa5608a-d671-4c50-ba9f-3bcf60c47aaa",
                        isTargetAudience: true
                    }
                },
                sourceDisplayName: "Lead party",
                sourcePrimaryId: "id",
                sourceSetName: null,
                targetAudience: [{ logicalName: "lead" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                dataType: "string",
                displayName: "Test event",
                logicalName: "testingpurposeonlyAttribute",
                sourceLogicalName: "msdynmkt_testingpurposeonly",
                languageCode: 1033,
                nativeId: "78c6cd06-a045-4279-8ea9-a96e12c011a0",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: ["msdynmkt_testingpurposeonly.testingpurposeonlyAttribute"],
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "7675608a-d671-4c50-ba9f-3bcf60c47e77",
                        isTargetAudience: true
                    }
                },
                sourceDisplayName: "Event",
                sourcePrimaryId: "id",
                sourceSetName: null,
                targetAudience: [{ logicalName: "contact" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                sourceLogicalName: "msdynmkt_dinner",
                sourcePrimaryId: "contactid",
                sourceSetName: null,
                sourceDisplayName: "Dinner",
                nativeId: "1f8bf64b-2425-478f-91dd-651a8ff21311",
                logicalName: "parentcontactid",
                displayName: "Parent Contact",
                languageCode: 1033,
                iconPath: null,
                isRequired: false,
                isSecured: false,
                dataType: "lookup",
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "7675608a-d671-4c50-ba9f-3bcf60c47e77",
                        isTargetAudience: false
                    }
                },
                navigationPaths: ["contact.parentcontactid"],
                fullMetadata: null,
                targetAudience: [{ logicalName: "contact" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                dataType: "string",
                displayName: "Place",
                logicalName: "dinner_place",
                sourceLogicalName: "msdynmkt_dinner",
                languageCode: 1033,
                nativeId: "78c6cd06-a045-4279-8ea9-a96e12c011a1",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: ["msdynmkt_dinner.dinner_place"],
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "7675608a-d671-4c50-ba9f-3bcf60c47e77",
                        isTargetAudience: true
                    }
                },
                sourceDisplayName: "Dinner",
                sourcePrimaryId: "id",
                sourceSetName: null,
                targetAudience: [{ logicalName: "contact" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                dataType: "string",
                displayName: "Lunch place",
                logicalName: "lunch_place",
                sourceLogicalName: "msdynmkt_dinner",
                languageCode: 1033,
                nativeId: "78c6cd06-a045-4279-8ea9-a96e12c011a1",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: ["msdynmkt_dinner.lunch_place"],
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "7675608a-d671-4c50-ba9f-3bcf60c47e77",
                        isTargetAudience: true
                    }
                },
                sourceDisplayName: "Dinner",
                sourcePrimaryId: "id",
                sourceSetName: null,
                targetAudience: [{ logicalName: "contact" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                sourceLogicalName: "msdynmkt_emptyevent",
                sourcePrimaryId: null,
                sourceSetName: null,
                sourceDisplayName: "Empty Event",
                nativeId: "e32b13ce-27c2-45c2-adea-78d4bf3f4a10",
                logicalName: "msdynmkt_profileid",
                displayName: "Profile Id",
                languageCode: 1033,
                iconPath: null,
                isRequired: true,
                isSecured: false,
                dataType: "string",
                referencedSources: {
                    contact: {
                        displayName: "Contact",
                        primaryId: "",
                        isTargetAudience: true
                    }
                },
                navigationPaths: ["msdynmkt_emptyevent.msdynmkt_profileid"],
                fullMetadata: null,
                targetAudience: [{ logicalName: "contact" }],
                businessUnit: null
            },
            {
                sourceType: SourceTypes.CjoCustomEvent,
                sourceLogicalName: "msdynmkt_emptyevent",
                sourcePrimaryId: null,
                sourceSetName: null,
                sourceDisplayName: "Empty Event",
                nativeId: "e32b13ce-27c2-45c2-adea-78d4bf3f4a10",
                logicalName: "msdynmkt_targetentity",
                displayName: "Target entity",
                languageCode: 1033,
                iconPath: null,
                isRequired: true,
                isSecured: false,
                dataType: "string",
                referencedSources: {
                    msdynci_customerprofile: {
                        displayName: "msdynci_customerprofile",
                        primaryId: "",
                        isTargetAudience: true
                    }
                },
                navigationPaths: ["msdynmkt_emptyevent.msdynmkt_targetentity"],
                fullMetadata: null,
                targetAudience: [{ logicalName: "msdynci_customerprofile" }],
                businessUnit: null
            }
        ];
        this.legal = [
            {
                sourceType: SourceTypes.Legal,
                dataType: "string",
                displayName: "Company Address",
                logicalName: "companyaddress",
                sourceLogicalName: "Default",
                languageCode: 1033,
                nativeId: "68369793-6c13-4264-aa74-44f5fdd5df24",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: null,
                referencedSources: null,
                sourceDisplayName: "Compliance",
                sourcePrimaryId: "id",
                sourceSetName: null,
                businessUnit: null
            },
            {
                sourceType: SourceTypes.Legal,
                dataType: "string",
                displayName: "Preference center",
                logicalName: "preferencescenterurl",
                sourceLogicalName: "Default",
                languageCode: 1033,
                nativeId: "e2f4c1ac-501c-42f9-b260-b4167ac0803d",
                fullMetadata: null,
                iconPath: "",
                isRequired: false,
                isSecured: false,
                navigationPaths: null,
                referencedSources: null,
                sourceDisplayName: "Compliance",
                sourcePrimaryId: "id",
                sourceSetName: null,
                businessUnit: null
            }
        ];
        this.datasetConfiguration = ["contact", "lead"];
        this.dateResponses = {
            "en-US": [
                {
                    format: "dddd, MMMM d, yyyy h:mm tt",
                    id: "FullDateTime",
                    resolved: "Saturday, November 12, 2022 4:30 PM",
                    group: "DateAndTime"
                },
                {
                    format: "dddd, MMMM d, yyyy H:mm",
                    id: "FullDateTime",
                    resolved: "Saturday, November 12, 2022 16:30",
                    group: "DateAndTime"
                },
                {
                    format: "dddd, MMMM d, yyyy H:mm GMT",
                    id: "FullDateTime",
                    resolved: "Saturday, November 12, 2022 16:30 (GMT+1)",
                    group: "DateAndTime"
                },
                {
                    format: "M\\/d\\/yyyy h:mm tt",
                    id: "GeneralDateTime",
                    resolved: "11\\/12\\/2022 4:30 PM",
                    group: "DateAndTime"
                },
                {
                    format: "M\\/d\\/yyyy h:mm:ss tt",
                    id: "GeneralDateTimeLong",
                    resolved: "11\\/12\\/2022 4:30:45 PM",
                    group: "DateAndTime"
                },
                {
                    format: "M\\/d\\/yyyy h:mm tt 'GMT'",
                    id: "DateTimeZone",
                    resolved: "11\\/12\\/2022 4:30 PM GMT",
                    group: "DateAndTime"
                },
                {
                    format: "dddd, MMMM d, yyyy",
                    id: "LongDate",
                    resolved: "Saturday, November 12, 2022",
                    group: "DateOnly"
                },
                {
                    format: "M\\/d\\/yyyy",
                    id: "ShortDate",
                    resolved: "11\\/12\\/2022",
                    group: "DateOnly"
                },
                {
                    format: "MMMM d",
                    id: "MonthDay",
                    resolved: "November 12",
                    group: "DateOnly"
                },
                {
                    format: "MMMM yyyy",
                    id: "YearMonth",
                    resolved: "November 2022",
                    group: "DateOnly"
                },
                {
                    format: "h:mm tt",
                    id: "TimeOnly",
                    resolved: "4:30 PM",
                    group: "DateAndTime"
                },
                {
                    format: "HH:mm",
                    id: "TimeOnly",
                    resolved: "16:30",
                    group: "DateAndTime"
                },
                {
                    format: "h:mm tt 'GMT'",
                    id: "TimeOnlyZone",
                    resolved: "4:30 PM GMT",
                    group: "DateAndTime"
                },
                {
                    format: "yyyy-MM-dd",
                    id: "UniversalDate",
                    resolved: "2022-11-12",
                    group: "DateAndTime"
                }
            ],
            "cs-CZ": [
                {
                    format: "dddd d. MMMM yyyy H:mm",
                    id: "FullDateTime",
                    resolved: "sobota 12. listopadu 2022 16:30",
                    group: "DateAndTime"
                },
                {
                    format: "dddd d. MMMM yyyy H:mm GMT",
                    id: "FullDateTime",
                    resolved: "sobota, 12. listopadu, 2022 16:30 (GMT+1)",
                    group: "DateAndTime"
                },
                {
                    format: "dd.MM.yyyy H:mm",
                    id: "GeneralDateTime",
                    resolved: "12.11.2022 16: 30",
                    group: "DateAndTime"
                },
                {
                    format: "dd.MM.yyyy H:mm:ss",
                    id: "GeneralDateTimeLong",
                    resolved: "12.11.2022 16: 30: 45",
                    group: "DateAndTime"
                },
                {
                    format: "dd.MM.yyyy H:mm 'GMT'",
                    id: "DateTimeZone",
                    resolved: "12.11.2022 16: 30 GMT",
                    group: "DateAndTime"
                },
                {
                    format: "dddd d. MMMM yyyy",
                    id: "LongDate",
                    resolved: "sobota 12. listopadu 2022",
                    group: "DateOnly"
                },
                {
                    format: "dd.MM.yyyy",
                    id: "ShortDate",
                    resolved: "12.11.2022",
                    group: "DateOnly"
                },
                {
                    format: "d. MMMM",
                    id: "MonthDay",
                    resolved: "12. listopadu",
                    group: "DateOnly"
                },
                {
                    format: "MMMM yyyy",
                    id: "YearMonth",
                    resolved: "listopad 2022",
                    group: "DateOnly"
                },
                {
                    format: "h:mm tt",
                    id: "TimeOnly",
                    resolved: "4:30 PM",
                    group: "DateAndTime"
                },
                {
                    format: "HH:mm",
                    id: "TimeOnly",
                    resolved: "16:30",
                    group: "DateAndTime"
                },
                {
                    format: "H:mm 'GMT'",
                    id: "TimeOnlyZone",
                    resolved: "16: 30 GMT",
                    group: "DateAndTime"
                },
                {
                    format: "yyyy-MM-dd",
                    id: "UniversalDate",
                    resolved: "2022-11-12",
                    group: "DateOnly"
                }
            ]
        };
    }
    MetadataStoreServiceMock.prototype.getBrandMetadataItems = function (useLazyRelatedEntitiesLoading) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, {
                        "@odata.context": "odataTestContext",
                        value: [
                            {
                                sourceType: SourceTypes.DataverseTable,
                                dataType: "string",
                                displayName: "Brand name",
                                logicalName: "msdynmkt_name",
                                sourceLogicalName: brandLogicalName,
                                languageCode: 1033,
                                nativeId: "68369793-6c13-4264-aa74-44f5fdd5df44",
                                fullMetadata: null,
                                iconPath: "",
                                isRequired: false,
                                isSecured: false,
                                navigationPaths: null,
                                referencedSources: null,
                                sourceDisplayName: "Brand",
                                sourcePrimaryId: "id",
                                sourceSetName: null,
                                businessUnit: null
                            },
                            {
                                sourceType: SourceTypes.DataverseTable,
                                dataType: "string",
                                displayName: "Brand description",
                                logicalName: "msdynmkt_branddescription",
                                sourceLogicalName: brandLogicalName,
                                languageCode: 1033,
                                nativeId: "68369793-6c13-4264-aa74-44f5fdd5d544",
                                fullMetadata: null,
                                iconPath: "",
                                isRequired: false,
                                isSecured: false,
                                navigationPaths: null,
                                referencedSources: null,
                                sourceDisplayName: "Brand",
                                sourcePrimaryId: "id",
                                sourceSetName: null,
                                businessUnit: null
                            }
                        ]
                    }];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getDisplayNames = function (sourceLogicalNames) {
        return __awaiter(this, void 0, void 0, function () {
            var result, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = Object).entries;
                        return [4 /*yield*/, this.getEntityMetadataFromCRM(sourceLogicalNames)];
                    case 1:
                        result = _b.apply(_a, [_c.sent()]).reduce(function (a, _a) {
                            var _b;
                            var _c = __read(_a, 2), k = _c[0], v = _c[1];
                            return (__assign(__assign({}, a), (_b = {}, _b[k] = v.DisplayName, _b)));
                        }, {});
                        return [2 /*return*/, result];
                }
            });
        });
    };
    MetadataStoreServiceMock.prototype.getEntityMetadataFromCRM = function (sourceLogicalNames, allowStaleData) {
        return __awaiter(this, void 0, void 0, function () {
            var sources, data, result;
            var _a;
            return __generator(this, function (_b) {
                sources = new Set(Array.isArray(sourceLogicalNames) ? sourceLogicalNames : [sourceLogicalNames]);
                data = (_a = {},
                    _a[this.lead[0].sourceLogicalName] = {
                        DisplayName: this.lead[0].sourceDisplayName,
                        LogicalName: this.lead[0].sourceLogicalName,
                        OwnershipType: "UserOwned"
                    },
                    _a[this.contact[0].sourceLogicalName] = {
                        DisplayName: this.contact[0].sourceDisplayName,
                        LogicalName: this.contact[0].sourceLogicalName,
                        OwnershipType: "UserOwned"
                    },
                    _a[this.ExternalPartyMetadata[0].sourceLogicalName] = {
                        DisplayName: this.ExternalPartyMetadata[0].sourceDisplayName,
                        LogicalName: this.ExternalPartyMetadata[0].sourceLogicalName,
                        OwnershipType: "UserOwned"
                    },
                    _a[this.msevtmgt_rooms[0].sourceLogicalName] = {
                        DisplayName: this.msevtmgt_rooms[0].sourceDisplayName,
                        LogicalName: this.msevtmgt_rooms[0].sourceLogicalName,
                        OwnershipType: "UserOwned"
                    },
                    _a[this.msevtmgt_buildings[0].sourceLogicalName] = {
                        DisplayName: this.msevtmgt_buildings[0].sourceDisplayName,
                        LogicalName: this.msevtmgt_buildings[0].sourceLogicalName,
                        OwnershipType: "UserOwned"
                    },
                    _a);
                result = Object.entries(data)
                    .filter(function (_a) {
                    var _b = __read(_a, 2), k = _b[0], v = _b[1];
                    return sources.has(k);
                })
                    .reduce(function (a, _a) {
                    var _b;
                    var _c = __read(_a, 2), k = _c[0], v = _c[1];
                    return (__assign(__assign({}, a), (_b = {}, _b[k] = v, _b)));
                }, {});
                return [2 /*return*/, result];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getAllMetadataEntities = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDisplayNames([
                            this.lead[0].sourceLogicalName,
                            this.contact[0].sourceLogicalName,
                            this.ExternalPartyMetadata[0].sourceLogicalName,
                            this.msevtmgt_rooms[0].sourceLogicalName,
                            this.msevtmgt_buildings[0].sourceLogicalName
                        ])];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, Promise.resolve(result)];
                }
            });
        });
    };
    MetadataStoreServiceMock.prototype.getDatasetConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.datasetConfiguration.map(function (x) {
                        return { sourceLogicalName: x, sourceDisplayName: x };
                    })];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getAllCjoEventsNames = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.eventError) {
                    throw "Failed to load metadata";
                }
                return [2 /*return*/, {
                        "@odata.context": "odataTestContext",
                        value: this.events.concat(this.additionalEvents).map(function (e) { return ({
                            sourceLogicalName: e.sourceLogicalName,
                            sourceDisplayName: e.sourceDisplayName,
                            targetAudience: Object.keys(e.referencedSources).map(function (x) {
                                return { logicalName: x };
                            }),
                            businessUnit: null
                        }); })
                    }];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getCjoEventsByName = function (eventNames) {
        return __awaiter(this, void 0, void 0, function () {
            var eventSet;
            return __generator(this, function (_a) {
                if (this.eventError) {
                    throw "Failed to load metadata";
                }
                eventSet = new Set(Array.isArray(eventNames) ? eventNames : [eventNames]);
                return [2 /*return*/, {
                        "@odata.context": "odataTestContext",
                        value: this.events.concat(this.additionalEvents).filter(function (e) { return eventSet.has(e.sourceLogicalName); })
                    }];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getMetadataItemsFor = function (sourceLogicalNames) {
        throw new Error("Method not implemented.");
    };
    MetadataStoreServiceMock.prototype.getAllLegalMetadataItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.legalError) {
                    throw "Failed to load metadata";
                }
                return [2 /*return*/, {
                        "@odata.context": "odataTestContext",
                        value: this.legal
                    }];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getDataverseTableMetadataItems = function (sourceLogicalNames) {
        return __awaiter(this, void 0, void 0, function () {
            var sourceArray, result;
            var _a, _b, _c, _d, _e;
            var _this = this;
            return __generator(this, function (_f) {
                if (this.dataverseErrror) {
                    throw "Failed to load metadata";
                }
                sourceArray = Array.isArray(sourceLogicalNames) ? sourceLogicalNames : [sourceLogicalNames];
                if (this.failingSourceName && sourceArray.find(function (k) { return k === _this.failingSourceName; }) != null) {
                    throw "Failed to load metadata";
                }
                result = {
                    "@odata.context": "odataTestContext",
                    value: []
                };
                if (sourceArray.find(function (k) { return k === "lead"; }) != null) {
                    (_a = result.value).push.apply(_a, __spreadArray([], __read(this.lead), false));
                }
                if (sourceArray.find(function (k) { return k === "contact"; }) != null) {
                    (_b = result.value).push.apply(_b, __spreadArray([], __read(this.contact), false));
                }
                if (sourceArray.find(function (k) { return k === "externalparty"; }) != null) {
                    (_c = result.value).push.apply(_c, __spreadArray([], __read(this.ExternalPartyMetadata), false));
                }
                if (sourceArray.find(function (k) { return k === "msevtmgt_room"; }) != null) {
                    (_d = result.value).push.apply(_d, __spreadArray([], __read(this.msevtmgt_rooms), false));
                }
                if (sourceArray.find(function (k) { return k === "msevtmgt_building"; }) != null) {
                    (_e = result.value).push.apply(_e, __spreadArray([], __read(this.msevtmgt_buildings), false));
                }
                return [2 /*return*/, result];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getAllCjoEventsMetadataItems = function (sourceLogicalNames) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.eventError) {
                    throw "Failed to load metadata";
                }
                return [2 /*return*/, {
                        "@odata.context": "odataTestContext",
                        value: this.events.concat(this.additionalEvents)
                    }];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getSpecificSegments = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, []];
            });
        });
    };
    MetadataStoreServiceMock.prototype.searchMetadataItemsContaining = function (tokenInDisplayName) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("Method not implemented.");
            });
        });
    };
    MetadataStoreServiceMock.prototype.serviceStart = function (appContext) { };
    MetadataStoreServiceMock.prototype.clearErrors = function () {
        this.legalError = this.eventError = this.dataverseErrror = false;
        this.failingSourceName = "";
    };
    MetadataStoreServiceMock.prototype.setupLegalError = function () {
        this.legalError = true;
    };
    MetadataStoreServiceMock.prototype.setupEventError = function () {
        this.eventError = true;
    };
    MetadataStoreServiceMock.prototype.setupDataverseError = function () {
        this.dataverseErrror = true;
    };
    MetadataStoreServiceMock.prototype.setupSpecificSourceDataverseError = function (sourceLogicalName) {
        this.failingSourceName = sourceLogicalName;
    };
    MetadataStoreServiceMock.prototype.preloadData = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    MetadataStoreServiceMock.prototype.getDateFormats = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            var fullResponse, options_1, result, options_2;
            return __generator(this, function (_a) {
                fullResponse = this.dateResponses[request.cultureName] || this.dateResponses["en-US"];
                if (request.dateOnly) {
                    options_1 = new Set(["LongDate", "ShortDate", "MonthDay", "YearMonth"]);
                    result = fullResponse.filter(function (f) { return options_1.has(f.id); });
                    return [2 /*return*/, result];
                }
                if (request.timezoneIndepentent) {
                    options_2 = new Set([
                        "FullDateTime",
                        "GeneralDateTime",
                        "GeneralDateTimeLong",
                        "LongDate",
                        "ShortDate",
                        "MonthDay",
                        "YearMonth",
                        "TimeOnly",
                        "UniversalDate"
                    ]);
                    return [2 /*return*/, fullResponse.filter(function (f) { return options_2.has(f.id); })];
                }
                return [2 /*return*/, fullResponse];
            });
        });
    };
    return MetadataStoreServiceMock;
}());
export { MetadataStoreServiceMock };
