var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Service } from "@cxp-shared/platform/Service";
import { TelemetryServiceName } from "@cxp-shared/services/Telemetry";
import { BusinessMetricsEventStatus } from "./IBusinessTelemetryService";
import { BusinessTelemetryLogger } from "./BusinessTelemetryLogger";
var BusinessTelemetryService = /** @class */ (function (_super) {
    __extends(BusinessTelemetryService, _super);
    function BusinessTelemetryService(app, subApp) {
        var _this = _super.call(this) || this;
        _this.version = "1";
        _this.app = app;
        _this.subApp = subApp;
        return _this;
    }
    BusinessTelemetryService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        var telemetryService = this.appContext.getService(TelemetryServiceName);
        this.logger = new BusinessTelemetryLogger(this.app, this.subApp, telemetryService);
    };
    BusinessTelemetryService.prototype.emitFunnelEvent = function (props) {
        this.logger.emitFunnelEvent(props);
    };
    BusinessTelemetryService.prototype.emitFunnelDiscover = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelDiscover(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitFunnelInitiate = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelInitiate(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitFunnelEngaged = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelEngaged(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitFunnelViewResults = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelViewResults(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitFunnelAction = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelAction(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitFunnelActivate = function (featureName, userId, eventStatus, additionalInfo) {
        if (eventStatus === void 0) { eventStatus = BusinessMetricsEventStatus.Success; }
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitFunnelAction(featureName, userId, eventStatus, additionalInfo);
    };
    BusinessTelemetryService.prototype.emitSAT = function (featureName, userId, eventValue, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = {}; }
        this.logger.emitSAT(featureName, userId, eventValue, additionalInfo);
    };
    return BusinessTelemetryService;
}(Service));
export { BusinessTelemetryService };
