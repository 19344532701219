export var TelemetryEvents;
(function (TelemetryEvents) {
    TelemetryEvents["DEPENDENCIES"] = "dependencies";
    TelemetryEvents["EVENTS"] = "events";
    TelemetryEvents["EXCEPTIONS"] = "exceptions";
    TelemetryEvents["FEATURE_USAGE_EVENTS"] = "feature_usage_events";
    TelemetryEvents["PAGE_VIEWS"] = "page_views";
    TelemetryEvents["SCENARIO_EVENTS"] = "scenario_events";
    TelemetryEvents["TRACES"] = "traces";
})(TelemetryEvents || (TelemetryEvents = {}));
export var BusinessMetricsEventStatus;
(function (BusinessMetricsEventStatus) {
    BusinessMetricsEventStatus["Failure"] = "Failure";
    BusinessMetricsEventStatus["Success"] = "Success";
})(BusinessMetricsEventStatus || (BusinessMetricsEventStatus = {}));
export var BusinessMetricsApp;
(function (BusinessMetricsApp) {
    BusinessMetricsApp["CIData"] = "CI Data";
    BusinessMetricsApp["CIJourneys"] = "CI Journeys";
    BusinessMetricsApp["MarketingCopilot"] = "Marketing Copilot";
})(BusinessMetricsApp || (BusinessMetricsApp = {}));
export var BusinessMetricsSubApp;
(function (BusinessMetricsSubApp) {
    BusinessMetricsSubApp["OBM"] = "OBM";
    BusinessMetricsSubApp["RTM"] = "RTM";
    BusinessMetricsSubApp["B2B"] = "B2B";
    BusinessMetricsSubApp["B2C"] = "B2C";
    BusinessMetricsSubApp["CopilotChat"] = "CopilotChat";
})(BusinessMetricsSubApp || (BusinessMetricsSubApp = {}));
export var BusinessMetricsFeatureType;
(function (BusinessMetricsFeatureType) {
    BusinessMetricsFeatureType["JourneyCopilot"] = "JourneyCopilot";
    BusinessMetricsFeatureType["EmailThemeCopilot"] = "EmailThemeCopilot";
    BusinessMetricsFeatureType["FormsThemeCopilot"] = "FormsThemeCopilot";
    BusinessMetricsFeatureType["ContentRewriteCopilot"] = "ContentRewriteCopilot";
    BusinessMetricsFeatureType["CIJCopilot"] = "CIJCopilot";
    BusinessMetricsFeatureType["LeadManagement"] = "LeadManagement";
    BusinessMetricsFeatureType["MarketingCopilotChat"] = "MarketingCopilotChat";
    BusinessMetricsFeatureType["PSNEmail"] = "PSNEmail";
    BusinessMetricsFeatureType["PSN"] = "PSN";
    BusinessMetricsFeatureType["PSNAudience"] = "PSNAudience";
})(BusinessMetricsFeatureType || (BusinessMetricsFeatureType = {}));
export var BusinessMetricsSATValue;
(function (BusinessMetricsSATValue) {
    BusinessMetricsSATValue[BusinessMetricsSATValue["Liked"] = 1] = "Liked";
    BusinessMetricsSATValue[BusinessMetricsSATValue["Disliked"] = -1] = "Disliked";
})(BusinessMetricsSATValue || (BusinessMetricsSATValue = {}));
