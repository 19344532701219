///<reference path="../typings/xrm.d.ts" />
import { EntityNames } from "../Common/Constants";
var excludedAttributes = ["msdynmkt_emailid", "msdynmkt_smsid", "msdynmkt_pushnotificationid", "msdynmkt_customchannelmessageid", "createdon", "ownerid"];
export function getSaveAsParameters(entityName, form) {
    switch (entityName) {
        case EntityNames.Email:
            return getEmailAttributes(form);
        case EntityNames.PushNotification:
        case EntityNames.Sms:
        case EntityNames.CustomChannelMessage:
            return getChannelEntityAttributes(form);
        case EntityNames.MarketingForm:
            return getMarketingEntityFormAttributes(form);
        default:
            return {};
    }
}
function getAttributes(form) {
    var attributes = form.getAttribute().filter(function (p) { return !excludedAttributes.includes(p.getName()); });
    var result = {};
    attributes.forEach(function (attribute) {
        result[attribute.getName()] = attribute.getValue();
    });
    return result;
}
function applyAttributeOverrides(attributes) {
    attributes["msdynmkt_name"] = "".concat(attributes["msdynmkt_name"], " Copy");
    attributes["statecode"] = 0;
    attributes["statuscode"] = 1;
    return attributes;
}
function getChannelEntityAttributes(form) {
    return applyAttributeOverrides(getAttributes(form));
}
function getMarketingEntityFormAttributes(form) {
    return applyAttributeOverrides(getAttributes(form));
}
function getEmailAttributes(form) {
    var attributes = getChannelEntityAttributes(form);
    attributes["msdynmkt_emailcontenttype"] = 534120000;
    attributes["prevent_templatepicker"] = true;
    return attributes;
}
