var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isBusinessUnitScopingEnabled } from "../Utilities/FeatureFlagsUtils";
import { resolveBusinessUnit } from "../BusinessUnitResolver";
import { predefinedPlaceholderToRecord, recordToPredefinedPlaceholder } from "./PredefinePlaceholdersDataAdapters";
var PredefinedPlaceholderDataService = /** @class */ (function () {
    function PredefinedPlaceholderDataService(dataService) {
        this.dataService = dataService;
    }
    PredefinedPlaceholderDataService.prototype.fetchByNameAndNamespace = function (name, namespace, language) {
        return __awaiter(this, void 0, void 0, function () {
            var query, records;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = "$filter=msdynmkt_namespace eq '".concat(encodeURI(namespace), "' and msdynmkt_description eq '").concat(encodeURI(name), "' and (msdynmkt_language eq ").concat(language, " or msdynmkt_language eq null)");
                        return [4 /*yield*/, this.dataService.getRecords(PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME, query)];
                    case 1:
                        records = _a.sent();
                        return [2 /*return*/, records === null || records === void 0 ? void 0 : records.map(function (r) { return recordToPredefinedPlaceholder(r); })];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.fetchByNamespace = function (namespace, language, businessUnit) {
        return __awaiter(this, void 0, void 0, function () {
            var businessUnitFilter, query, records;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBusinessUnitFilter(businessUnit)];
                    case 1:
                        businessUnitFilter = _a.sent();
                        query = "$filter=msdynmkt_namespace eq '".concat(encodeURI(namespace), "' and (msdynmkt_language eq ").concat(language, " or msdynmkt_language eq null) ").concat(businessUnitFilter);
                        return [4 /*yield*/, this.dataService.getRecords(PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME, query)];
                    case 2:
                        records = _a.sent();
                        return [2 /*return*/, records === null || records === void 0 ? void 0 : records.map(function (r) { return recordToPredefinedPlaceholder(r); })];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.createRecord = function (predefinedPlaceholder) {
        return __awaiter(this, void 0, void 0, function () {
            var record;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        record = predefinedPlaceholderToRecord(predefinedPlaceholder);
                        return [4 /*yield*/, this.dataService.createRecord(PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME, record)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.patchRecord = function (id, predefinedPlaceholder) {
        return __awaiter(this, void 0, void 0, function () {
            var record;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        record = predefinedPlaceholderToRecord(predefinedPlaceholder);
                        return [4 /*yield*/, this.dataService.patchRecord(PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME, id, record)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.updatePlaceholderUseCount = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.executePlugin("msdynmkt_PredefinedPlaceholderAddCount", {
                            PredefinedPlaceholderAddCountRequest: JSON.stringify({
                                predefinedPlaceholderId: id
                            })
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.deleteRecord = function (recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.deleteRecord(PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME, recordId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.prototype.getBusinessUnitFilter = function (businessUnit) {
        return __awaiter(this, void 0, void 0, function () {
            var isBuScopingEnabled, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, isBusinessUnitScopingEnabled()];
                    case 1:
                        isBuScopingEnabled = _c.sent();
                        if (!isBuScopingEnabled) return [3 /*break*/, 3];
                        _b = "and _owningbusinessunit_value eq '".concat;
                        return [4 /*yield*/, resolveBusinessUnit(businessUnit)];
                    case 2:
                        _a = _b.apply("and _owningbusinessunit_value eq '", [_c.sent(), "'"]);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = "";
                        _c.label = 4;
                    case 4: return [2 /*return*/, _a];
                }
            });
        });
    };
    PredefinedPlaceholderDataService.PREDEFINED_PLACEHOLDER_ENTITY_SET_NAME = "msdynmkt_predefinedplaceholders";
    return PredefinedPlaceholderDataService;
}());
export { PredefinedPlaceholderDataService };
