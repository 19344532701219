var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ConditionTypes, TransformationType, UnaryOperator } from "@cxp-shared/contracts";
import { CachedMetadataStoreServiceName } from "@cxp-shared/metadata";
import { Service } from "@cxp-shared/platform/Service";
import { DataSources } from "../Constants/DataSources";
import { PlaceholderUtils } from "../Utilities/PlaceholderUtils";
import { extractDataverseTargetAudience } from "./DataSources/DataverseTargetExtractor";
import { extractEventTargetAudience, extractTargetEventSourceLogicalName, isEventBindingTargetAudience } from "./DataSources/EventsTargetExtractor";
import { extractSegmentsTargetAudience, isSegmentBindingTargetAudience } from "./DataSources/SegmentationTargetExtractor";
import { EventEntity, EventRegistrationEntity } from "@cxp-shared/metadata-constants";
var TargetEntitiesExtractorService = /** @class */ (function (_super) {
    __extends(TargetEntitiesExtractorService, _super);
    function TargetEntitiesExtractorService(metadataStoreService) {
        if (metadataStoreService === void 0) { metadataStoreService = null; }
        var _this = _super.call(this) || this;
        _this.metadataStoreService = metadataStoreService;
        _this.extractAllUsedEntities = function (bindings) {
            var selectedItems = bindings.flatMap(function (i) { return _this.getBindingItems(i) || []; });
            var audienceSources = selectedItems.filter(function (i) {
                return i.source === DataSources.CdsDataSource ||
                    i.source === DataSources.DataverseDataSource ||
                    i.source === DataSources.SalesDataSource ||
                    i.source === DataSources.JourneyDataSource ||
                    i.source === DataSources.EventRegistrationFormDataSource;
            });
            var eventSources = selectedItems.filter(function (i) { return i.source === DataSources.EventDataSource; });
            var result = {
                dataverse: audienceSources.map(function (a) { return a.sourceType; }),
                events: eventSources.map(function (e) { return e.sourceType; })
            };
            return result;
        };
        _this.extractAllPlaceholderBindings = function (placeholders) {
            var bindings = Object.values(placeholders || [])
                .flatMap(function (placeholder) { return _this.extractBindingsFromPlaceholder(placeholder); })
                .filter(function (b) { return b; });
            return bindings;
        };
        _this.extractAllPlaceholderBindingsWithCategory = function (placeholders, categories) {
            var bindings = Object.values(placeholders || [])
                .filter(function (placeholder) { return categories.includes(placeholder.category); })
                .flatMap(function (placeholder) { return _this.extractBindingsFromPlaceholder(placeholder); })
                .filter(function (b) { return b; });
            return bindings;
        };
        _this.extractAllTriggerPlaceholderBindings = function (placeholders) {
            var bindings = Object.values(placeholders || [])
                .flatMap(function (placeholder) { return _this.extractBindingsFromPlaceholder(placeholder); })
                .filter(function (b) { return b; })
                .filter(function (b) { var _a; return ((_a = PlaceholderUtils.getRootBinding(b)) === null || _a === void 0 ? void 0 : _a.source) === DataSources.EventDataSource; });
            return bindings;
        };
        _this.extractTargetAudience = function (placeholders) { return __awaiter(_this, void 0, void 0, function () {
            var bindings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        bindings = this.extractAllPlaceholderBindings(placeholders);
                        return [4 /*yield*/, this.extractTargetAudienceFromBindings(bindings)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.extractCdsAndSegmentTargetAudience = function (placeholders) { return __awaiter(_this, void 0, void 0, function () {
            var targetAudience, bindings, cdsBindings, eventBindings, segmentBindings, segmentAudience;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        targetAudience = { targetEntities: [] };
                        bindings = this.extractAllPlaceholderBindings(placeholders);
                        cdsBindings = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.CdsDataSource; });
                        eventBindings = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.EventDataSource; });
                        segmentBindings = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.SegmentMembershipCheckDataSource; });
                        if (cdsBindings.length) {
                            targetAudience.targetEntities = extractDataverseTargetAudience(cdsBindings);
                        }
                        targetAudience.targetEvent = extractTargetEventSourceLogicalName(eventBindings);
                        return [4 /*yield*/, extractSegmentsTargetAudience(segmentBindings, this.metadataStoreService)];
                    case 1:
                        segmentAudience = _a.sent();
                        return [2 /*return*/, {
                                targetAudience: targetAudience,
                                segmentAudience: segmentAudience
                            }];
                }
            });
        }); };
        _this.extractTargetAudienceFromAudiencePlaceholders = function (placeholders) {
            var bindings = _this.extractAllPlaceholderBindingsWithCategory(placeholders, ["general", "list"]);
            return _this.extractTargetAudienceFromAudienceBindings(bindings);
        };
        _this.isTargetAudience = function (targetAudience, placeholder, exactAudienceMatchOnly) { return __awaiter(_this, void 0, void 0, function () {
            var noAudienceSelected, noEventSelected, bindings, selectedBindings, firstBinding, dataSource, sourceType, _a;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        noAudienceSelected = !(targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEntities) || targetAudience.targetEntities.length === 0;
                        noEventSelected = !(targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEvent);
                        if (!exactAudienceMatchOnly && noAudienceSelected && noEventSelected) {
                            return [2 /*return*/, true];
                        }
                        if (placeholder == null) {
                            return [2 /*return*/, false];
                        }
                        if (PlaceholderUtils.isStaticBinding(placeholder.binding)) {
                            return [2 /*return*/, true];
                        }
                        bindings = this.extractBindingsFromPlaceholder(placeholder);
                        if (!(bindings === null || bindings === void 0 ? void 0 : bindings.length)) {
                            return [2 /*return*/, false];
                        }
                        selectedBindings = this.getBindingItems(bindings[0]);
                        firstBinding = selectedBindings === null || selectedBindings === void 0 ? void 0 : selectedBindings[0];
                        dataSource = firstBinding === null || firstBinding === void 0 ? void 0 : firstBinding.source;
                        sourceType = firstBinding === null || firstBinding === void 0 ? void 0 : firstBinding.sourceType;
                        _a = dataSource;
                        switch (_a) {
                            case DataSources.BrandDataSource: return [3 /*break*/, 1];
                            case DataSources.LegalDataSource: return [3 /*break*/, 1];
                            case DataSources.ViewEmailInBrowserUrlDataSource: return [3 /*break*/, 1];
                            case DataSources.EventDataSource: return [3 /*break*/, 2];
                            case DataSources.SegmentMembershipCheckDataSource: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 6];
                    case 1: return [2 /*return*/, true];
                    case 2:
                        if (exactAudienceMatchOnly && noAudienceSelected && noEventSelected) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, isEventBindingTargetAudience(sourceType, targetAudience, this.metadataStoreService, exactAudienceMatchOnly)];
                    case 3: return [2 /*return*/, _d.sent()];
                    case 4:
                        if (exactAudienceMatchOnly && noAudienceSelected) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, isSegmentBindingTargetAudience((_c = (_b = bindings[0].inputs) === null || _b === void 0 ? void 0 : _b.segmentId) === null || _c === void 0 ? void 0 : _c.value, targetAudience, this.metadataStoreService)];
                    case 5: return [2 /*return*/, _d.sent()];
                    case 6:
                        if (exactAudienceMatchOnly && noAudienceSelected) {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.targetEntities.some(function (te) { return te === sourceType; })];
                }
            });
        }); };
        _this.extractAllReferencedEntities = function (placeholders) {
            var result = [];
            Object.keys(placeholders || {}).forEach(function (key) {
                var placeholder = placeholders[key];
                if (PlaceholderUtils.isStaticBinding(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding)) {
                    return;
                }
                var bindings = _this.extractBindingsFromPlaceholder(placeholder);
                bindings.forEach(function (binding) {
                    var selectedBindings = _this.getBindingItems(binding) || [];
                    selectedBindings.forEach(function (item) {
                        var sourceType = item === null || item === void 0 ? void 0 : item.sourceType;
                        var datasource = item === null || item === void 0 ? void 0 : item.source;
                        if (datasource === DataSources.EventDataSource ||
                            datasource === DataSources.CdsDataSource ||
                            datasource === DataSources.DataverseDataSource) {
                            if (sourceType && !result.some(function (b) { return b.datasource === datasource && b.sourceType === sourceType; })) {
                                result.push({ datasource: datasource, sourceType: sourceType });
                            }
                        }
                    });
                });
            });
            return result;
        };
        _this.extractBaseAudiencesFromPredefinedPlaceholder = function (predefinedPlaceholder) { return __awaiter(_this, void 0, void 0, function () {
            var result, bindings;
            var _this = this;
            var _a, _b;
            return __generator(this, function (_c) {
                result = [];
                bindings = ((_b = (_a = predefinedPlaceholder === null || predefinedPlaceholder === void 0 ? void 0 : predefinedPlaceholder.placeholderdescriptor) === null || _a === void 0 ? void 0 : _a.alternateBindings) === null || _b === void 0 ? void 0 : _b.map(function (k) { return k.binding; })) || [];
                bindings.forEach(function (binding) {
                    var targetAudience = _this.extractDatasource(binding);
                    if ((targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.sourceType) &&
                        !result.some(function (b) { return b.datasource === targetAudience.datasource && b.sourceType === targetAudience.sourceType; })) {
                        result.push(__assign(__assign({}, targetAudience), { binding: binding }));
                    }
                });
                return [2 /*return*/, result];
            });
        }); };
        _this.isInternalPlaceholderWithFilteredAudience = function (placeholder) {
            if (placeholder === null || placeholder === void 0 ? void 0 : placeholder.isInternal) {
                return _this.extractDatasource(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding) ? true : false;
            }
            return false;
        };
        _this.getBindingItems = function (binding) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
            if (binding == null) {
                return [];
            }
            var result = {
                output: binding.outputPath,
                source: binding.source,
                sourceType: (_b = (_a = binding === null || binding === void 0 ? void 0 : binding.inputs) === null || _a === void 0 ? void 0 : _a.sourceType) === null || _b === void 0 ? void 0 : _b.value,
                referencedTable: null
            };
            if (((_c = binding === null || binding === void 0 ? void 0 : binding.inputs) === null || _c === void 0 ? void 0 : _c.relatingRecordId) != null) {
                // list placeholder
                result.source = (_f = (_e = (_d = binding.inputs) === null || _d === void 0 ? void 0 : _d.relatingRecordId) === null || _e === void 0 ? void 0 : _e.binding) === null || _f === void 0 ? void 0 : _f.source;
                result.sourceType = (_l = (_k = (_j = (_h = (_g = binding.inputs) === null || _g === void 0 ? void 0 : _g.relatingRecordId) === null || _h === void 0 ? void 0 : _h.binding) === null || _j === void 0 ? void 0 : _j.inputs) === null || _k === void 0 ? void 0 : _k.sourceType) === null || _l === void 0 ? void 0 : _l.value;
                result.output = (_o = (_m = binding.inputs) === null || _m === void 0 ? void 0 : _m.relationshipName) === null || _o === void 0 ? void 0 : _o.value;
            }
            var resultArray = [result];
            var relatedBinding = _this.getRelatedBinding(binding);
            if (relatedBinding) {
                var selectedItemsSoFar = _this.getBindingItems(relatedBinding);
                if (selectedItemsSoFar.length > 0) {
                    selectedItemsSoFar[selectedItemsSoFar.length - 1].referencedTable = result.sourceType;
                }
                resultArray = __spreadArray(__spreadArray([], __read(selectedItemsSoFar), false), __read(resultArray), false);
            }
            var rightOperandPlaceholderRootBinding = PlaceholderUtils.getRightOperandPlaceholderRootBinding(binding);
            if (rightOperandPlaceholderRootBinding) {
                var selectedItemsSoFar = _this.getBindingItems(rightOperandPlaceholderRootBinding);
                if (selectedItemsSoFar.length > 0) {
                    var bindingItem = selectedItemsSoFar[selectedItemsSoFar.length - 1];
                    if (bindingItem.source === DataSources.EventDataSource) {
                        bindingItem.referencedTable = ((_p = rightOperandPlaceholderRootBinding === null || rightOperandPlaceholderRootBinding === void 0 ? void 0 : rightOperandPlaceholderRootBinding.outputPath) === null || _p === void 0 ? void 0 : _p.endsWith("_eventregistrationid"))
                            ? EventRegistrationEntity.EntityName
                            : EventEntity.EntityName;
                    }
                    else {
                        bindingItem.referencedTable = bindingItem.sourceType;
                    }
                }
                resultArray = __spreadArray(__spreadArray([], __read(selectedItemsSoFar), false), __read(resultArray), false);
            }
            else if (PlaceholderUtils.isFirstOfManyPlaceholder(binding)) {
                if (binding.outputs) {
                    var outputBinding = _this.extractOutputBinding(binding);
                    var outputItems = _this.getBindingItems(outputBinding);
                    resultArray[resultArray.length - 1].referencedTable = (_q = outputItems === null || outputItems === void 0 ? void 0 : outputItems[0]) === null || _q === void 0 ? void 0 : _q.sourceType;
                    resultArray.push.apply(resultArray, __spreadArray([], __read(outputItems), false));
                }
                else {
                    var outputItem = {
                        output: binding.outputPath,
                        source: binding.source,
                        sourceType: (_s = (_r = binding === null || binding === void 0 ? void 0 : binding.inputs) === null || _r === void 0 ? void 0 : _r.sourceType) === null || _s === void 0 ? void 0 : _s.value,
                        referencedTable: null
                    };
                    resultArray[resultArray.length - 1].referencedTable = outputItem.sourceType;
                    resultArray.push(outputItem);
                }
            }
            return resultArray;
        };
        _this.extractBindingFromConditionExpression = function (expression) {
            switch (expression === null || expression === void 0 ? void 0 : expression.type) {
                case ConditionTypes.grouping: {
                    var groupingExpression = expression;
                    var result = groupingExpression.expressions.flatMap(function (e) { return _this.extractBindingFromConditionExpression(e); });
                    return result;
                }
                case ConditionTypes.binaryOperator: {
                    var binaryExpression = expression;
                    var leftOperandPlaceholder = binaryExpression.leftOperand;
                    var rightOperandPlaceholderParameter = binaryExpression.rightOperand;
                    var bindings = _this.extractBindingsFromPlaceholder(leftOperandPlaceholder);
                    return bindings.concat(_this.extractBindingsFromPlaceholder(rightOperandPlaceholderParameter === null || rightOperandPlaceholderParameter === void 0 ? void 0 : rightOperandPlaceholderParameter.placeholder));
                }
                case ConditionTypes.unaryOperator: {
                    var unaryExpression = expression;
                    if (unaryExpression.operator === UnaryOperator.isNotSegmentMember || unaryExpression.operator === UnaryOperator.isSegmentMember) {
                        var operand = unaryExpression.operand;
                        // Create temporary binding object to allow further processing together with other bindings
                        return [
                            {
                                source: DataSources.SegmentMembershipCheckDataSource,
                                outputType: "string",
                                inputs: {
                                    segmentId: {
                                        value: operand.value
                                    },
                                    sourceType: {
                                        value: "msdyncrm_segment"
                                    }
                                }
                            }
                        ];
                    }
                    var placeholder = unaryExpression.operand;
                    return _this.extractBindingsFromPlaceholder(placeholder);
                }
                default:
                    return [];
            }
        };
        _this.extractTargetAudienceFromBindings = function (bindings) { return __awaiter(_this, void 0, void 0, function () {
            var result, sortedBindings, dataverseTargetAudience, segementTargetAudience, eventTargetAudience;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = { targetEntities: [] };
                        sortedBindings = this.sortBindingsByLoadingPriority(bindings);
                        dataverseTargetAudience = extractDataverseTargetAudience(sortedBindings.dataverse);
                        result.targetEntities = dataverseTargetAudience;
                        if (!(result.targetEntities.length !== 1 && sortedBindings.segmentItems.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, extractSegmentsTargetAudience(sortedBindings.segmentItems, this.metadataStoreService)];
                    case 1:
                        segementTargetAudience = _a.sent();
                        result.targetEntities = segementTargetAudience;
                        _a.label = 2;
                    case 2:
                        if (!(result.targetEntities.length !== 1 && sortedBindings.eventItems.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, extractEventTargetAudience(sortedBindings.eventItems, this.metadataStoreService)];
                    case 3:
                        eventTargetAudience = _a.sent();
                        result.targetEntities = eventTargetAudience;
                        _a.label = 4;
                    case 4:
                        result.targetEvent = extractTargetEventSourceLogicalName(sortedBindings.eventItems);
                        return [2 /*return*/, result.targetEntities.length > 0 ? result : null];
                }
            });
        }); };
        _this.extractTargetAudienceFromAudienceBindings = function (bindings) {
            var cdsBindings = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(PlaceholderUtils.getRootBinding(b))) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.CdsDataSource; });
            var dataverseTargetAudience = extractDataverseTargetAudience(cdsBindings);
            var result = dataverseTargetAudience.length ? dataverseTargetAudience[0] : null;
            return result;
        };
        _this.sortBindingsByLoadingPriority = function (bindings) {
            var cdsItems = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.CdsDataSource; });
            var dataverseItems = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.DataverseDataSource; });
            var eventItems = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.EventDataSource; });
            var segmentItems = bindings.filter(function (b) { var _a; return ((_a = _this.extractDatasource(b)) === null || _a === void 0 ? void 0 : _a.datasource) === DataSources.SegmentMembershipCheckDataSource; });
            return {
                dataverse: __spreadArray(__spreadArray([], __read(cdsItems), false), __read(dataverseItems), false),
                segmentItems: segmentItems,
                eventItems: eventItems
            };
        };
        _this.extractBindingsFromPlaceholder = function (placeholder) {
            var _a, _b, _c, _d, _e;
            if (!(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding)) {
                return [];
            }
            if (placeholder.binding.source === DataSources.None) {
                if (((_a = placeholder.transformation) === null || _a === void 0 ? void 0 : _a.type) === TransformationType.ComplexExpression) {
                    var transformation = placeholder.transformation;
                    var result = _this.extractBindingFromConditionExpression(transformation.expression);
                    return result;
                }
            }
            if ((_c = (_b = placeholder.binding.inputs) === null || _b === void 0 ? void 0 : _b.filterConditionExpression) === null || _c === void 0 ? void 0 : _c.value) {
                var conditionExpression = (_e = (_d = placeholder.binding.inputs) === null || _d === void 0 ? void 0 : _d.filterConditionExpression) === null || _e === void 0 ? void 0 : _e.value;
                var result = _this.extractBindingFromConditionExpression(conditionExpression);
                return result.filter(function (i) { return i.source !== DataSources.FilterBaseSource; }).concat([placeholder.binding]);
            }
            return [placeholder.binding];
        };
        _this.extractDatasource = function (binding) {
            if (PlaceholderUtils.isStaticBinding(binding)) {
                return null;
            }
            var selectedBindings = _this.getBindingItems(binding);
            var bindingsPresent = !!selectedBindings && selectedBindings.length > 0;
            if (bindingsPresent) {
                var firstBinding = selectedBindings[0];
                var sourceType = firstBinding === null || firstBinding === void 0 ? void 0 : firstBinding.sourceType;
                var datasource = firstBinding === null || firstBinding === void 0 ? void 0 : firstBinding.source;
                if (datasource === DataSources.LegalDataSource) {
                    return null;
                }
                if (!datasource || !sourceType) {
                    return null;
                }
                return { datasource: datasource, sourceType: sourceType };
            }
            else {
                return null;
            }
        };
        _this.extractOutputBinding = function (binding) {
            var _a, _b, _c;
            if (!binding || Object.values(binding.outputs).length <= 0) {
                return null;
            }
            var baseSourceType = (_b = (_a = binding === null || binding === void 0 ? void 0 : binding.inputs) === null || _a === void 0 ? void 0 : _a.sourceType) === null || _b === void 0 ? void 0 : _b.value;
            var baseSource = binding === null || binding === void 0 ? void 0 : binding.source;
            var outputBinding = JSON.parse(JSON.stringify((_c = Object.values(binding.outputs)[0]) === null || _c === void 0 ? void 0 : _c.binding));
            _this.updateOutputBinding(outputBinding, baseSourceType, baseSource);
            return outputBinding;
        };
        _this.updateOutputBinding = function (binding, sourceType, source) {
            var rootBinding = PlaceholderUtils.getRootBinding(binding);
            if (rootBinding.source === DataSources.ContextOutputBase || rootBinding.source === DataSources.ContextOutputBaseRelated) {
                rootBinding.source = source;
                rootBinding.inputs = { sourceType: { value: sourceType } };
            }
        };
        _this.getRelatedBinding = function (binding) {
            var _a, _b, _c, _d, _e, _f, _g;
            return ((_e = (_d = (_c = (_b = (_a = binding.inputs) === null || _a === void 0 ? void 0 : _a.relatingRecordId) === null || _b === void 0 ? void 0 : _b.binding) === null || _c === void 0 ? void 0 : _c.inputs) === null || _d === void 0 ? void 0 : _d.recordId) === null || _e === void 0 ? void 0 : _e.binding) || ((_g = (_f = binding.inputs) === null || _f === void 0 ? void 0 : _f.recordId) === null || _g === void 0 ? void 0 : _g.binding);
        };
        _this.getPlaceholdersWithAudienceCount = function (placeholders) {
            if (!placeholders) {
                return 0;
            }
            var count = Object.values(placeholders).filter(function (placeholder) {
                if ((placeholder === null || placeholder === void 0 ? void 0 : placeholder.category) === "condition") {
                    var bindings = _this.extractAllPlaceholderBindings({ placeholder: placeholder });
                    return bindings.some(function (b) { return (b === null || b === void 0 ? void 0 : b.source) === DataSources.CdsDataSource || (b === null || b === void 0 ? void 0 : b.source) === DataSources.EventDataSource; });
                }
                var rootBinding = PlaceholderUtils.getRootBinding(placeholder === null || placeholder === void 0 ? void 0 : placeholder.binding);
                return (rootBinding === null || rootBinding === void 0 ? void 0 : rootBinding.source) === DataSources.CdsDataSource || (rootBinding === null || rootBinding === void 0 ? void 0 : rootBinding.source) === DataSources.EventDataSource;
            }).length;
            return count;
        };
        return _this;
    }
    TargetEntitiesExtractorService.prototype.serviceStart = function (appContext) {
        _super.prototype.serviceStart.call(this, appContext);
        if (!this.metadataStoreService) {
            this.metadataStoreService = appContext.getService(CachedMetadataStoreServiceName);
        }
        else {
            this.metadataStoreService.serviceStart(appContext);
        }
    };
    return TargetEntitiesExtractorService;
}(Service));
export { TargetEntitiesExtractorService };
